import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getAdTests } from '@buzzfeed/adlib/dist/module/services/ab-tests/standalone';
import { getAmpClientId } from '@buzzfeed/client-event-tracking/dist/lib/utils';

import { ABeagleContext } from '@/context';
import EXPERIMENTS from '@/constants/ab-tests';
import { useABeagleExperiments } from '@buzzfeed/react-components';
import { ABEAGLE_HOST } from '@/constants';
import tracking from '@/services/tracking';
import { isAdPost } from '@/utils/ad-utils';
import { isServer } from '@/utils/misc-utils';
import { getUserGeoCountry } from '@buzzfeed/bf-utils/lib/localization';

function ABeagle({ children, pageProps }) {
  const [clientId, setClientId] = useState(null);

  const isAd = isAdPost(pageProps);

  // Ads experiments require additional page context for eligibility checks
  const AD_TESTS = getAdTests({
    isBPage: false,
    isNewBPage: false,
    isFeedPage: false,
    isBFO: false,
    isBFN: false,
    destination: 'tasty',
    localizationCountry: 'en-us',
    userCountry: isServer() ? '' : getUserGeoCountry(),
    edition: 'en-us',
    isAdPost: () => isAd,
    pageSection: pageProps.pageType
  });


  const memoizedData = useMemo(() => pageProps, [pageProps.pagePath]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
    * Inside hook, will fetch once clientId is a defined value, otherwise will return
    *   default experiments object
    */
  const experiments = useABeagleExperiments({
    abeagleHost: ABEAGLE_HOST,
    data: memoizedData,
    experimentConfig: [...EXPERIMENTS, ...AD_TESTS],
    source: 'tasty_web',
    userId: clientId,
  });
  const pagePath = pageProps.pagePath;
  const returnedExperiments = useRef(null);
  // keep track of page path for which experiments were fetched
  if (!returnedExperiments.current || returnedExperiments.current.loaded !== experiments.loaded) {
    returnedExperiments.current = {
      ...experiments,
      pagePath,
    };
  }
  // mark previous experiments as stale while experiments for the new page are being fetched
  returnedExperiments.current.stale = (returnedExperiments.current.pagePath !== pagePath);
  const loaded = returnedExperiments.current.loaded;

  useEffect(() => {
    /**
     * While still using PDv1 for experiments, use amp client id to keep parity.
     * For V3 will use standard BuzzFeed client_uuid value instead
     */
    setClientId(getAmpClientId());
  }, []);

  useEffect(() => {
    if (loaded) {
      const experimentsToTrack = [];
      Object.keys(experiments.eligible).forEach((key) => {
        const experiment = experiments.eligible[key];
        // Only send status if bucketed (has variant in 'value')
        if (experiment.value) {
          experimentsToTrack.push({
            id: experiment.id,
            name: key,
            version: experiment.version,
            variantId: experiment.variant_id,
            variantName: experiment.value,
          });
        }
      });
      tracking.trackExperimentsActive({
        experiments: experimentsToTrack,
        pageInfo: pageProps.analyticsInfo,
      });
    }
  }, [pagePath, loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ABeagleContext.Provider value={{ experiments: returnedExperiments.current }}>
      {children}
    </ABeagleContext.Provider>
  );
}

ABeagle.propTypes = {
  pageProps: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default ABeagle;

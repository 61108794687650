/*
    there is no way to pass env config to next.js
    so we'll need to create stage/prod variants
    and use those in the app to decide how to call
*/
import getConfig from 'next/config';
import { isServer } from '@/utils/misc-utils';

const { publicRuntimeConfig } = getConfig();

// all types of pages handled by the service
const PAGE_TYPES_ALL = [
  'accessibility',
  'article',
  'brand',
  'contest',
  'compilation',
  'feature',
  'mycart',
  'guides',
  'in-app-only',
  'ingredient',
  'ingredients',
  'home',
  'latest',
  'lidl-fiver-feast',
  'my-meal-plan-my-way',
  'recipe',
  'recipe-buy',
  'search',
  'submit-recipe',
  'summer-of-beans',
  'tag',
  'topic',
  'tastyvalues',
  'zelle-savor-the-city'
];

const TIPS_PAGE_SIZE = 12;

const NUTRITION_ORDER = ['calories', 'fat', 'carbohydrates', 'fiber', 'sugar', 'protein'];

const API_PROXY_URL = isServer() ? `http://localhost:${process.env.PORT}` : '';

const {
  abeagle_host,
  adadapted_api_key,
  advertise_with_tasty_url,
  bf_url,
  cdn_max_age_seconds,
  CLUSTER,
  client_nav_enabled,
  facebook_app_id,
  facebook_page_id,
  filter_tag_categories,
  google_recaptcha_sitekey,
  google_recaptcha_sitekey_v2,
  gtm_enabled,
  image_service_host,
  NODE_ENV,
  permutive_api_key,
  permutive_project_id,
  privacy_policy_url,
  RELEASE,
  RIG_DEPLOYMENT_TYPE,
  sentry_public_dsn,
  site_description,
  tasty_url,
  user_agreement_url,
  video_service_host,
} = publicRuntimeConfig;

export {
  abeagle_host as ABEAGLE_HOST,
  adadapted_api_key as ADADAPTED_API_KEY,
  advertise_with_tasty_url as ADVERTISE_WITH_TASTY_URL,
  API_PROXY_URL,
  bf_url as BF_URL,
  cdn_max_age_seconds as CDN_MAX_AGE_SECONDS,
  CLUSTER,
  client_nav_enabled as CLIENT_NAV_ENABLED,
  filter_tag_categories as FILTER_TAG_CATEGORIES,
  facebook_app_id as FACEBOOK_APP_ID,
  facebook_page_id as FACEBOOK_PAGE_ID,
  google_recaptcha_sitekey as GOOGLE_RECAPTCHA_SITEKEY,
  google_recaptcha_sitekey_v2 as GOOGLE_RECAPTCHA_SITEKEY_V2,
  gtm_enabled,
  image_service_host as IMAGE_SERVICE_HOST,
  NODE_ENV,
  NUTRITION_ORDER,
  PAGE_TYPES_ALL,
  permutive_api_key as PERMUTIVE_API_KEY,
  permutive_project_id as PERMUTIVE_PROJECT_ID,
  privacy_policy_url as PRIVACY_POLICY_URL,
  RELEASE,
  RIG_DEPLOYMENT_TYPE,
  sentry_public_dsn as SENTRY_PUBLIC_DSN,
  site_description as SITE_DESCRIPTION,
  tasty_url as TASTY_URL,
  TIPS_PAGE_SIZE,
  user_agreement_url as USER_AGREEMENT_URL,
  video_service_host as VIDEO_SERVICE_HOST,
};

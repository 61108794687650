import { PermutiveTracker } from '@buzzfeed/third-party-analytics';

import {
  CLUSTER,
  PERMUTIVE_PROJECT_ID,
  PERMUTIVE_API_KEY,
} from '@/constants';

let trackingInstance;

const permutive = () => {
  if (!trackingInstance) {
    trackingInstance = new PermutiveTracker({
      cluster: CLUSTER,
      projectId: PERMUTIVE_PROJECT_ID,
      apiKey: PERMUTIVE_API_KEY,
    });
  }
  return trackingInstance;
};

export async function trackPermutivePageView(pageProps) {
  const tracker = permutive();
  const { analyticsInfo, article, recipe } = pageProps;
  let edition = '';
  if (article && article.classification) {
    edition = (article.classification.edition || '').toLowerCase();
  } else if (pageProps[pageProps.pageType] && pageProps[pageProps.pageType].country) {
    edition = pageProps[pageProps.pageType].country.toLowerCase();
  }
  let tags = [];
  if (['recipe', 'compilation'].includes(pageProps.pageType)) {
    tags = pageProps[pageProps.pageType].tags.map(tag => tag.name);
  } else if (article) {
    tags = article.tags;
  }
  const eventData = {
    page: {
      meta: {
        author: analyticsInfo.author,
        bf_buzzid: analyticsInfo.context_page_id,
        bf_userid: analyticsInfo.author_id,
        compilationid: pageProps.compilation ? pageProps.compilation.id : '',
        description: analyticsInfo.description,
        edition,
        keywords: recipe && recipe.keywords ? recipe.keywords.split(', ') : [],
        platform: 'web',
        publisher: 'tasty',
        recipeid: recipe ? recipe.id : '',
        section: pageProps.pageType,
        show: recipe && recipe.show ? recipe.show.name : '',
        tags,
        title: analyticsInfo.title,
        type: pageProps.pageType,
        videoid: analyticsInfo.has_video ? pageProps[pageProps.pageType].video_id : '',
      },
    },
  };
  tracker.trackPageView(eventData);
}

